import * as React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import { Page, IngredientList, Section, NavBar } from "src/components"
import { styled, breakpoints } from "src/styles/theme"

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`

const StyledDescriptionContainer = styled.div`
  flex: 2 1 0;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    flex: 1;
    width: 100%;
    padding-left: 0;
  }
`

const StyledIngredientListContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  @media screen and (max-width: ${breakpoints.sm}) {
    flex: 1;
    width: 100%;
    padding: 2rem 0;
  }
`

const StyledImage = styled(Image)`
  height: 20vh;
  width: 20vh;
  border-radius: 10vh;
  margin: 0 2vw;
`

const StyledImageSet = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const query = graphql`
  query RecipeTemplate($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: $slug } } }) {
      nodes {
        html
        excerpt
        frontmatter {
          title
          subtitle
          images {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ingredientSections {
            title
            items {
              title
              quantity
              unit
            }
          }
        }
      }
    }
  }
`

const RecipeTemplate: React.FC<{ data: GatsbyTypes.RecipeTemplateQuery }> = ({
  data
}) => {
  const pageData = data.allMarkdownRemark?.nodes[0]

  let images: Array<React.ReactElement> = []
  let index = 1
  pageData?.frontmatter?.images.forEach((image) => {
    if (image?.childImageSharp?.fluid) {
      images.push(<StyledImage key={index} fluid={image.childImageSharp.fluid} />)
      index += 1
    }
  })

  return (
    <Page>
      <NavBar />
      <Section>
        <h1 className="desktop-centered">{pageData?.frontmatter?.title}</h1>
        <h4 className="desktop-centered">{pageData?.frontmatter?.subtitle}</h4>
      </Section>
      <br />
      <br />
      <Section>
        <StyledImageSet>{images}</StyledImageSet>
      </Section>
      <br />
      <br />
      <Section>
        <StyledContentContainer>
          <StyledIngredientListContainer>
            <IngredientList data={data} />
          </StyledIngredientListContainer>
          <StyledDescriptionContainer>
            {/* eslint-disable-next-line react/no-danger */}
            <div
              className="recipe"
              dangerouslySetInnerHTML={{ __html: pageData?.html || "" }}
            />
          </StyledDescriptionContainer>
        </StyledContentContainer>
      </Section>
    </Page>
  )
}

export default RecipeTemplate
